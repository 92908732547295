import $ from "jquery";
import toastr from "toastr";

import intlTelInput from "intl-tel-input";
// TODO: RAILS_UPGRADE - Include css from npm package
import "intl-tel-input/build/css/intlTelInput.css";

$(function () {
  const input = $("input.int-tel-input[type=tel]")[0];
  if (input) {
    const clonedInput = $(input)
      .clone()
      .attr({
        name: "user[phone_number]",
        class: "hidden",
        required: false,
        id: "clones_user_phone_number",
      })
      .insertBefore(input);

    intlTelInput(input, { formatOnDisplay: true });
    const iti = intlTelInput(input);

    window.intlTelInputGlobals
      .loadUtils(
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js"
      )
      .then(() => {
        var countryCode =
          $("#int-tel-initial-country-code")[0] &&
          $("#int-tel-initial-country-code")[0].dataset.countryCode;
        if (iti.getNumber() === "" && countryCode) {
          iti.setCountry(countryCode.toLowerCase());
        }

        $(clonedInput)
          .closest("form")
          .on("submit", (ev) => {
            assignValue();
            if (!iti.isValidNumber()) {
              ev.preventDefault();
              ev.stopPropagation();

              $(clonedInput)
                .closest("form")
                .find(":submit")
                .prop("disabled", false);

              $(window).scrollTop($(input).offset().top - 80);
              $(input).trigger("focus");
              assignValue();
            }
          });

        input.addEventListener("focusout", () => {
          assignValue();
        });
      });

    function assignValue() {
      if (iti.isValidNumber()) {
        iti.setNumber(iti.getNumber());
        $(clonedInput).val(iti.getNumber());
      } else if (iti.getNumber() !== "") {
        $(clonedInput).val("");
        iti.setNumber("");
        toastr.error("Please enter a valid phone number");
      }
    }
  }
});
