import $ from 'jquery';

const jsTruncate = {
  truncateText: (className) => {
    var showChar = 250;
    var ellipsestext = '...';
    var elements;
    elements = className
      ? $('.' + className + ' .js_truncate')
      : $('.js_truncate');

    elements.each(function () {
      var content = $(this).html();
      if (content.length > showChar) {
        var c = content.substr(0, showChar);
        var h = content;
        var html =
          '<div class="truncate-text" style="display:block">' +
          c +
          '<span class="moreellipses">' +
          ellipsestext +
          '&nbsp;&nbsp;<a href="" class="moreless more text-green-700">+ Read more</a></span></span></div><div class="truncate-text" style="display:none">' +
          h +
          '<a href="" class="moreless less text-green-700"> Less</a></span></div>';

        $(this).html(html);
      }
    });
  },
  toggleTruncatedText: () => {
    $(document).on('click', '.moreless', function () {
      var thisEl = $(this);
      var cT = thisEl.closest('.truncate-text');
      var tX = '.truncate-text';

      if (thisEl.hasClass('less')) {
        cT.prev(tX).toggle();
        cT.slideToggle();
      } else {
        cT.toggle();
        cT.next(tX).fadeToggle();
      }
      return false;
    });
  },
  init: () => {
    jsTruncate.truncateText();
    jsTruncate.toggleTruncatedText();
  },
};

window.jsTruncate = {
  init: jsTruncate.init,
};
