import $ from "jquery";
import "../../../../vendor/assets/javascripts/jquery-bar-rating.min";

function mentorsBarRatings(event) {
  $(".jquery-bars-theme-course").barrating({
    theme: "bars-1to10",
    deselectable: false,
    onSelect: function (value, text, event) {
      if (typeof event !== "undefined") {
        var dataset = this.$elem.data();
        $.ajax({
          url: "/ratings/" + dataset.ratedId + "/rate_course_mentor",
          data: {
            rating: {
              course_id: dataset.courseId,
              rating: value,
              rated_id: dataset.ratedId,
            },
          },
          method: "POST",
          error: function (data) {
            json_data = data.responseJSON;
            if (json_data.rating) {
              $(
                "#course_rating_" + json_data.courseId + "_" + json_data.ratedId
              ).barrating("set", json_data.rating);
            } else {
              $(
                "#course_rating_" + json_data.courseId + "_" + json_data.ratedId
              ).barrating("clear");
            }
            toastr.error("Rating Not Updated!");
          },
          success: function (data) {
            var element = document.querySelector(
              `#course_rating_info_${dataset.courseId}_${dataset.ratedId}`
            );
            if (element !== null) {
              element.remove();
            }
            toastr.success("Rating recorded!");
          },
        });
      }
    },
  });
}

function localLeaderRatings() {
  $(".jquery-bars-theme-director").barrating({
    theme: "bars-1to10",
    deselectable: false,
    onSelect: function (value, text, event) {
      if (typeof event !== "undefined") {
        var dataset = this.$elem.data();
        $.ajax({
          url: "/ratings/" + dataset.ratedId + "/rate_facilitator",
          data: {
            rating: {
              rating: value,
              rated_id: dataset.ratedId,
            },
          },
          method: "POST",
          error: function (data) {
            json_data = data.responseJSON;
            if (json_data.rating) {
              $("#director_rating_" + json_data.ratedId).barrating(
                "set",
                json_data.rating
              );
            } else {
              $("#director_rating_" + json_data.ratedId).barrating("clear");
            }
            toastr.error("Rating Not Updated!");
          },
          success: function (data) {
            var element = document.querySelector(
              `#director_rating_info_${dataset.ratedId}`
            );
            if (element !== null) {
              element.remove();
            }
            toastr.success("Rating recorded!");
          },
        });
      }
    },
  });
}

function workingGroupRatings() {
  $(".jquery-bars-theme-team-member-flag").barrating({
    theme: "bars-1to10",
    onSelect: function (value, text, event) {
      if (typeof event !== "undefined") {
        var dataset = this.$elem.data();
        $.ajax({
          url: "/ratings/" + dataset.ratedId + "/rate_member",
          data: {
            rating: {
              rating: value,
              rated_id: dataset.ratedId,
              flag: dataset.flag,
            },
          },
          method: "POST",
          error: function (data) {
            json_data = data.responseJSON;
            if (json_data.rating) {
              $(
                "#member_" + json_data.flag + "_rating_" + json_data.ratedId
              ).barrating("set", json_data.rating);
            } else {
              $(
                "#member_" + json_data.flag + "_rating_" + json_data.ratedId
              ).barrating("clear");
            }
            toastr.error("Rating Not Updated!");
          },
          success: function (data) {
            toastr.success("Rating recorded!");
          },
        });
      }
    },
  });
  $(".jquery-bars-theme-team-members").barrating({
    theme: "bars-square",
    showValues: true,
    deselectable: false,
    onSelect: function (value, text, event) {
      if (typeof event !== "undefined") {
        var dataset = this.$elem.data();
        $.ajax({
          url: "/ratings/" + dataset.ratedId + "/rate_member",
          data: {
            rating: {
              rating: value,
              rated_id: dataset.ratedId,
              flag: dataset.flag,
            },
          },
          method: "POST",
          error: function (data) {
            json_data = data.responseJSON;
            if (json_data.rating) {
              $(
                "#member_" + json_data.flag + "_" + json_data.ratedId
              ).barrating("set", json_data.rating);
            } else {
              $(
                "#member_" + json_data.flag + "_" + json_data.ratedId
              ).barrating("clear");
            }
            toastr.error("Rating Not Updated!");
          },
          success: function (data) {
            toastr.success("Rating recorded!");
          },
        });
      }
    },
  });
}

window.addDOMContentOrTurboLoadedEventListener(mentorsBarRatings);
window.addDOMContentOrTurboLoadedEventListener(localLeaderRatings);
window.addDOMContentOrTurboLoadedEventListener(workingGroupRatings);

window.addDOMContentOrTurboLoadedEventListener(
  mentorsBarRatings,
  "turbo:frame-load"
);
