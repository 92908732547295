export const circleProgress = (canvas) => {
  const ctx = canvas.getContext("2d");
  const computedStyle = getComputedStyle(canvas);
  const percent = parseFloat(canvas.dataset.percent);
  const precision = parseInt(canvas.dataset.precision);
  const radius = canvas.clientHeight || 256;
  let metricUnits =
    canvas.dataset.units === "false" ? "" : canvas.dataset.units || "%";

  canvas.width = 2 * radius;
  canvas.height = 2 * radius;
  ctx.lineWidth = 0.2 * radius;
  ctx.lineCap = "round";

  ctx.beginPath();
  ctx.arc(radius, radius, radius - ctx.lineWidth * 0.5, 0, 2 * Math.PI, 0);
  ctx.strokeStyle = "#eaeaea";
  ctx.stroke();

  ctx.beginPath();
  ctx.arc(
    radius,
    radius,
    radius - ctx.lineWidth * 0.5,
    0.5 * Math.PI,
    (0.5 + 0.02 * parseInt(canvas.dataset.percent)) * Math.PI,
    0
  );
  ctx.strokeStyle = computedStyle.color;
  ctx.stroke();
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.font = `bold ${radius * 0.45}px ${computedStyle.fontFamily}`;
  ctx.fillStyle = computedStyle.color;
  ctx.fillText(`${percent.toFixed(precision)}${metricUnits}`, radius, radius);
};
