import $ from "jquery";

window.closeModal = function () {
  $(".modal").not(".hidden").addClass("hidden");
};

$(document).on("click", ".closeModal", (ev) => {
  ev.preventDefault();

  closeModal();
});

$(document).on("click", ".modal-open", (ev) => {
  ev.preventDefault();

  let data_id = ev.currentTarget.dataset.id;

  $(`.modal${data_id}`).toggleClass("hidden");
});
