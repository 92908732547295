import $ from 'jquery';
import { circleProgress } from '../components/circle-progress';

export const floatingAnchor = (anchorNodeSelector) => {
  const anchorNode = $(anchorNodeSelector);

  if (anchorNode.length) {
    const handleWindowScroll = () => {
      const targetNode = $(anchorNode.data('target'));
      const hideAnchor =
        $(window).scrollTop() + $(window).height() > targetNode.offset().top;
      anchorNode.toggleClass('invisible pointer-events-none', hideAnchor);
    };

    handleWindowScroll();
    window.addEventListener('scroll', handleWindowScroll, true);
  }
};

window.addEventListener('load', () => {
  document.querySelectorAll('canvas.circle-progress').forEach(circleProgress);
  floatingAnchor('#floating-anchor');
});
