import $ from 'jquery';


$(document).on('click', ()=>{
  $(".dropdown-menu").not(".hidden").addClass('hidden').removeClass('z-10');
});

$(document).on('click', '.dropdown', (ev) => {
  ev.stopPropagation();

  $(ev.currentTarget).find('.dropdown-menu').toggleClass('hidden z-10');
});
