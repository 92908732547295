/* global grecaptcha */
import $ from "jquery";
import toastr from "toastr";

var GDPRModule = {
  showGDPRConsentForm: function (formScope) {
    $("#gdprConsentModal").toggleClass("hidden");

    $("#gdprConsentModal .reject-gdpr").on("click", function () {
      GDPRModule.updateConsentSession("decline");
      $("#gdprConsentModal").toggleClass("hidden");
      $("form.need-gdpr-consent")
        .find(":input[type=submit]")
        .prop("disabled", false)
        .text("Apply Now");
      toastr.remove();
      toastr.error(
        "You have not been registered for this event and no data has been saved to our servers."
      );
    });

    $("#gdprConsentModal .accept-gdpr").click(function () {
      GDPRModule.updateConsentSession("approve");
      $("#gdprConsentModal").toggleClass("hidden");
      if ($(formScope).is("form")) {
        if (typeof grecaptcha !== "undefined") {
          setTimeout(() => {
            grecaptcha.execute();
          }, 100);
        } else {
          $(formScope).submit();
        }
      } else {
        window.location.href = formScope.href;
      }
    });
  },

  initializeConsentSession: function (formIdentifier) {
    $.post("/gdpr/consent_session", {
      form_identifier: formIdentifier,
      status: "start",
    });
  },

  updateConsentSession: function (status) {
    $.post("/gdpr/consent_session", {
      status: status,
    });
  },
};

$(function () {
  window.submitInvisibleRecaptchaForm = function () {
    $(".invisible-recaptcha-form").off("submit");
    $(".invisible-recaptcha-form").submit();
    InvisibleRecaptchaForm();
  };

  const InvisibleRecaptchaForm = function () {
    $(".invisible-recaptcha-form").on("submit", function (ev) {
      ev.preventDefault();
      $(this).find(":submit").attr("disabled", true);
      $(this).addClass("submitted");
      $(".invisible-recaptcha-form").not(".submitted").remove();
      if ($("#gdprConsentModal").length > 0) {
        GDPRModule.initializeConsentSession(
          this.dataset.gdprConsentFormIdentifier
        );
        GDPRModule.showGDPRConsentForm(this);
      } else {
        setTimeout(() => {
          grecaptcha.execute();
        }, 100);
      }
    });
  };

  InvisibleRecaptchaForm();

  if ($("#gdprConsentModal").length > 0) {
    $(".need-gdpr-consent-social").on("click", function (e) {
      e.preventDefault();
      GDPRModule.initializeConsentSession(
        this.dataset.gdprConsentFormIdentifier
      );
      GDPRModule.showGDPRConsentForm(this);
    });
  }
});
