import $ from "jquery";
import flatpickr from "flatpickr";
import { min } from "lodash";

window.datetime_flatpickr = function () {
  flatpickr(".datetime-flatpickr", {
    enableTime: true,
    altInput: true,
    inline: true,
  });
};

window.date_flatpickr = function () {
  flatpickr(".date-flatpickr", {
    altInput: true,
    inline: true,
  });
};

function initializeStartEndDatePickers() {
  const startDateTimePicker = flatpickr(".start-datetime-picker", {
    enableTime: true,
    altInput: true,
    altFormat: "F j, Y h:i K",
    dateFormat: "Y-m-d H:i",
    minuteIncrement: 15,
    onChange: function (selectedDates, dateStr, instance) {
      const minEndDate = selectedDates[0];
      endDateTimePicker.set("minDate", minEndDate);

      const endDate = new Date(minEndDate);
      endDate.setHours(endDate.getHours() + 1);
      endDateTimePicker.setDate(endDate);
    },
  });
  const endDateTimePicker = flatpickr(".end-datetime-picker", {
    enableTime: true,
    altInput: true,
    altFormat: "F j, Y h:i K",
    dateFormat: "Y-m-d H:i",
    minuteIncrement: 15,
  });
}

$(function () {
  datetime_flatpickr();
  date_flatpickr();
});

window.addDOMContentOrTurboLoadedEventListener(initializeStartEndDatePickers);
