import SignaturePad from 'signature_pad';
import $ from 'jquery';

// var canvas = document.querySelector("canvas");
// var signaturePad = new SignaturePad(canvas);

// console.log(signaturePad);

var signaturePad = (function() {
  var canvas;
  var pad;

  var initPad = function() {
    canvas = document.querySelector("canvas");
    pad = new SignaturePad(canvas);

    if(pad) {

      canvas.style.height = '100%';
      canvas.style.width = '100%';
      canvas.height = canvas.offsetHeight;
      canvas.width = canvas.offsetWidth;

      window.addEventListener("resize", resizeCanvas);
      resizeCanvas();

      $(".signature_pad_clear").on('click', () => {
        pad.clear();
      });

    }
  }

  var resizeCanvas = function() {
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    pad.clear();
  }

  var cropSignatureCanvas = function() {
    // First duplicate the canvas to not alter the original
    var croppedCanvas = document.createElement("canvas"),
      croppedCtx = croppedCanvas.getContext("2d");

    croppedCanvas.width = canvas.width;
    croppedCanvas.height = canvas.height;
    croppedCtx.drawImage(canvas, 0, 0);

    // Next do the actual cropping
    var w = croppedCanvas.width,
      h = croppedCanvas.height,
      pix = { x: [], y: [] },
      imageData = croppedCtx.getImageData(
        0,
        0,
        croppedCanvas.width,
        croppedCanvas.height
      ),
      x,
      y,
      index;

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x);
          pix.y.push(y);
        }
      }
    }
    pix.x.sort(function(a, b) {
      return a - b;
    });
    pix.y.sort(function(a, b) {
      return a - b;
    });
    var n = pix.x.length - 1;

    w = pix.x[n] - pix.x[0];
    h = pix.y[n] - pix.y[0];
    var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

    croppedCanvas.width = w;
    croppedCanvas.height = h;
    croppedCtx.putImageData(cut, 0, 0);

    return croppedCanvas.toDataURL();
  }

  var openModal = function() {
    $('.signature-pad__open').on('click', (e)=> {
      e.preventDefault();
      $('#signatureModal').removeClass('hidden');
    });
  }

  var clearSignature = function() {
    $('.signature-pad__clear').on('click', (e)=> {
      e.preventDefault();
      $('.signature_pad__input').val(null);
      $('.signature-pad__preview').attr('src', null);
      pad.clear();
    });
  }

  var closeSignature = function() {
    $('.signature-pad__close').on('click', (e)=> {
      e.preventDefault();
      $('#signatureModal').addClass('hidden');
    });
  }

  var saveSignature = function() {
    $('.signature-pad__save').on('click', (e)=> {
      e.preventDefault();
      let data = cropSignatureCanvas();
      $('.signature_pad__input').val(data);
      $('.signature-pad__preview').attr('src', data);
      $('#signatureModal').addClass('hidden');
    });
  }

  var initModalOptions = function() {
    openModal();
    clearSignature();
    closeSignature();
    saveSignature();
  }

  var init = function() {
    initPad();
    initModalOptions();
  }

  return {
    init
  }
})();

window.signaturePad = signaturePad;
