import $ from 'jquery';
import * as linkify from 'linkifyjs';

$(function() {
  $('.linkify-input').on('blur', (ev)=>{
    let str = linkify.find(ev.currentTarget.value);
    if (str && str[0]) {
      ev.currentTarget.value = str && str[0]['href']
    }
  });
})
