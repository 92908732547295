const map = new Map();

const observer = new IntersectionObserver((entries) => {
  entries.forEach(({ target, isIntersecting }) => {
    map.get(target).classList.toggle("hidden", isIntersecting);
  });
});

export default {
  init(trigger) {
    const target = document.querySelector(
      trigger.dataset.target || trigger.hash
    );
    if (target) {
      map.set(target, trigger);
      observer.observe(target);
    }
  },
};

/**
 * Usage:
 * <a href="#target-id" x-data="{}" x-init="Alpine.componenets.FloatingAnchor.init"> ... </a>
 * or
 * <a href="#target-id" x-data="{}" x-init="function() { Alpine.components.FloatingAnchor.init.apply(this); }"> ... </a>
 */
