import toastr from 'toastr';
import $ from 'jquery';

$(function() {
  if (gon.toastr_success_message) {
    toastr.success(gon.toastr_success_message);
  } else if (gon.toastr_error_message) {
    toastr.error(gon.toastr_error_message);
  }
});
