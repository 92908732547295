import $ from "jquery";
import tippy from "tippy.js";

$(function () {
  tippy(".tippy-html", {
    content(reference) {
      const id = reference.getAttribute("data-template");
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    allowHTML: true,
    maxWidth: "35rem",
    interactive: true,
    appendTo: document.body,
    placement: "auto",
    theme: "light",
  });

  tippy(".tippy-html-md", {
    content(reference) {
      const id = reference.getAttribute("data-template");
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    allowHTML: true,
    maxWidth: "25rem",
    interactive: true,
    appendTo: document.body,
    placement: "auto",
    theme: "light",
  });

  tippy(".tippy-default", {
    content(reference) {
      const id = reference.getAttribute("data-template");
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    allowHTML: true,
    interactive: true,
    appendTo: document.body,
    placement: "auto",
  });

  tippy(
    document.querySelectorAll(".tippy-data-element", {
      maxWidth: 250,
      allowHTML: true,
      appendTo: () => document.body,
    })
  );
});
