import $ from "jquery";

document.addEventListener("turbo:load", function () {
  const spinner = Object.assign(document.createElement("i"), {
    classList: "fi-icon-spinner animate-spin",
  });
  $(".stripe-payment-btn").on("click", async function () {
    this.disabled = true;
    this.appendChild(spinner);
    $(this).find(".fi-icon-arrow-right").remove();
    try {
      const stripe = Stripe(this.dataset.key);
      const session = await (
        await fetch(`/payments/stripe`, {
          method: "POST",
          headers: {
            "X-CSRF-Token": $("meta[name='csrf-token']").attr("content"),
          },
        })
      ).json();
      const response = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (response.error) toastr.error(response.error.message);
    } catch (_) {
      toastr.error(
        "Couldn't connect to payments provider. Please try again after some time"
      );
    }
    this.removeChild(spinner);
    this.disabled = false;
  });
});
