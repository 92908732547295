import $ from 'jquery';

window.addEventListener('load', () => {

  if ($('.superadmin_event_star_icon').length > 0) {
    $(document).on('click', '.superadmin_event_star_icon', (ev) => {
      const this_ele = ev.currentTarget;
      const status = $(this_ele).hasClass('fi-icon-star-outlined')
        ? 'Star Event'
        : null;

      toggleClasses(this_ele, status);

      $.ajax({
        url: `/admin/courses/${this_ele.dataset.id}/star_event`,
        method: 'PUT',
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            'X-CSRF-Token',
            $('meta[name="csrf-token"]').attr('content')
          );
        },
        data: { status: status },
        dataType: 'json',
        error: function () {
          toggleClasses(!status);
          toastr.error(
            `We were unable to mark/unmark ${this_ele.dataset.name}.\nPlease try again!`
          );
        },
      });
    });

    function toggleClasses(currentScope, addStar) {
      if (addStar) {
        $(currentScope).removeClass('fi-icon-star-outlined');
        $(currentScope).addClass('fi-icon-star text-yellow-600');
      } else {
        $(currentScope).addClass('fi-icon-star-outlined');
        $(currentScope).removeClass('fi-icon-star text-yellow-600');
      }
    }
  }

  if ($('select#past_events_dropdown').length > 0) {
    $('select#past_events_dropdown').on('change', (ev) => {
      const val = ev.target.value;
      if (val === 'Show All Local') {
        $('.past_event').removeClass('past_event_hide');
        $('.non_local_event').addClass('non_local_event_hide');
      } else if (val === 'Show All') {
        $('.past_event').removeClass('past_event_hide');
        $('.non_local_event').removeClass('non_local_event_hide');
      } else if (val === 'Show All Upcoming') {
        $('.past_event').addClass('past_event_hide');
        $('.non_local_event').removeClass('non_local_event_hide');
      } else if (val === 'Show All Upcoming Local') {
        $('.past_event').addClass('past_event_hide');
        $('.non_local_event').addClass('non_local_event_hide');
      }
    });
    $('select#past_events_dropdown').trigger('change');
  }
});
