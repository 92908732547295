import $ from 'jquery';

$(document).on('click', "[data-toggle='collapse']", (ev) => {
  let id = ev.currentTarget.dataset.id;
  $(ev.currentTarget).find('.rotate-icon').toggleClass('rotate-180');
  $(ev.currentTarget).parents().find(`#collapse_${id}`).toggleClass('hidden');
});


// table row collapse
$(document).on('click', "[data-toggle='table_collapse']", (ev) => {
  let className = ev.currentTarget.dataset.class;
  $(ev.currentTarget).find('.rotate-icon').toggleClass('rotate-180');
  if($(`.${className}`).hasClass('hidden')) {
    $(`.${className}`).removeClass('hidden');
    $(ev.currentTarget).parents('tr').addClass('bg-gray-100');
  }else {
    $(`.${className}`).addClass('hidden');
    $(ev.currentTarget).parents('tr').removeClass('bg-gray-100');
  }
});
