/* eslint no-console:0 */
// TODO: RAILS_UPGRADE - Ensure that rails_ujs is working.
//  If not working, try to add something like `import './rails-ujs';`
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import Splide from "@splidejs/splide";
import collapse from "@alpinejs/collapse";
import "jquery";
import "regenerator-runtime/runtime";
import "notyf/notyf.min.css";
import "./v4/utils";
import "./v4/pages";
import "./v4/components/gdpr";
import "./utils/luxon-date-formatter";

import FloatingAnchor from "./v4/components/floating-anchor";
import Impactor from "./v4/components/impactor";
import RecaptchaForm from "./v4/components/recaptcha-form";
import Sortable from "sortablejs";

window.$ = window.jQuery = require("jquery");
window.toastr = require("toastr");
window.Alpine = Alpine;
window.Alpine.components = { Impactor, FloatingAnchor, RecaptchaForm };
window.Sortable = Sortable;
window.SVGInject = require("@iconfu/svg-inject");
window.Splide = Splide;
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(collapse);

Alpine.store("benefits", {
  activeTab: 1,
  switchTab(tab) {
    this.activeTab = tab;
  },
});

$(() => {
  Alpine.start();
});

window.fetchCSRFToken = function () {
  return document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
};
