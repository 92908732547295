import $ from 'jquery';

const callTool = {
  handleUserStar: () => {
    $('.user_block .user_star').on('click', (ev) => {
      $(ev.currentTarget).toggleClass('text-brand-700 text-gray-500');
      const { id } = $(ev.currentTarget).data();
      const form = document.querySelector(`form#call_assigned_role_${id}`);
      const isStarred = $(ev.currentTarget).hasClass('text-brand-700');
      $(`form#call_assigned_role_${id}`).find('input[name="tags"]').val(isStarred ? 'Starred' : '');
      $(`form#call_assigned_role_${id}`).find('input[name="triggered_by"]').val('Star');
      form.dispatchEvent(new Event('submit', { bubbles: true }));
    });
  },
  handleAssignedTo: () => {
    $('.user_block select[name="assigned_to"]').on('change', (ev) => {
      const id = $(ev.currentTarget).attr('id');
      $(`form#call_assigned_role_${id}`).find('input[name="triggered_by"]').val('');
      const form = document.querySelector(`form#call_assigned_role_${id}`);
      form.dispatchEvent(new Event('submit', { bubbles: true }));
    });
  },
  handleSortBy: () => {
    $('select[name="sort_by"]').on('change', (ev) => {
      const val = $(ev.currentTarget.options).filter(':selected').val();
      $('form#filter_form').find('input[name="sort_by"]').val(val);
      $('form#filter_form').submit();
    });
  },
  founderToggler: () => {
    $('.founder_toggler').on('click', (ev) => {
      $(ev.currentTarget).find('.rotate_icon').toggleClass('rotate-180');
      $(ev.currentTarget).parent('.user_block').find('#infomation-block').slideToggle();
      $(ev.currentTarget).parent('.user_block').toggleClass('bg-gray-100').toggleClass('shadow-sm');
    });
  },
  founderInfoToggler: () => {
    $('.founder_info_block').on('click', (ev) => {
      $(ev.currentTarget).find('.rotate_icon').toggleClass('rotate-180');
      $(ev.currentTarget).siblings('.semester_info').slideToggle('hidden');
    });
  },
  init: () => {
    callTool.handleUserStar();
    callTool.handleAssignedTo();
    callTool.handleSortBy();
    callTool.founderToggler();
    callTool.founderInfoToggler();
  },
};

window.callTool = {
  init: callTool.init,
};
