import $ from "jquery";
import { DateTime } from "luxon";
import select2 from "select2"; // global variables used by jQuery
import "select2/dist/css/select2.min.css";

function TimeZoneFormatter() {
  function fuzzysearch(needle, haystack) {
    var hlen = haystack.length;
    var nlen = needle.length;
    if (nlen > hlen) {
      return false;
    }
    if (nlen === hlen) {
      return needle === haystack;
    }
    outer: for (var i = 0, j = 0; i < nlen; i++) {
      var nch = needle.charCodeAt(i);
      while (j < hlen) {
        if (haystack.charCodeAt(j++) === nch) {
          continue outer;
        }
      }
      return false;
    }
    return true;
  }

  $("select.semester_switcher_select2").select2({
    width: "100%",
    // dropdownParent: $('.location-preference-modal .location-preference-modal_dropdown-parent'),
    templateResult: (state) => {
      if (!state.element) return state.text;
      return `${state.text}`;
    },
    matcher: function (params, data) {
      // If there are no search terms, return all of the data
      if ($.trim(params.term) === "") {
        return data;
      }

      // Do not display the item if there is no 'text' property
      if (typeof data.text === "undefined") {
        return null;
      }

      // `params.term` should be the term that is used for searching
      // `data.text` is the text that is displayed for the data object
      if (fuzzysearch(params.term.toUpperCase(), data.text.toUpperCase())) {
        var modifiedData = $.extend({}, data, true);

        // You can return modified objects from here
        // This includes matching the `children` how you want in nested data sets
        return modifiedData;
      }

      // Return `null` if the term should not be displayed
      return null;
    },
  });

  $("select.semester_switcher_select2, select.timezone_switcher_select2").on(
    "change",
    (ev) => {
      $(ev.currentTarget).closest("form").submit();
    }
  );

  const getLuxonTimezone = (() => {
    const _cache = {};
    return (tzName) => {
      if (!_cache[tzName])
        _cache[tzName] = DateTime.fromObject({}, { zone: tzName });
      return _cache[tzName];
    };
  })();

  $("select.timezone_switcher_select2").select2({
    width: "100%",
    // dropdownParent: $('.location-preference-modal .location-preference-modal_dropdown-parent'),
    matcher: (() => {
      const _aliasCache = {};
      const getAlias = (data) => {
        const value = data.element.value;
        if (!_aliasCache[value])
          _aliasCache[value] = `${getLuxonTimezone(value).toFormat(
            "ZZZZ ZZZZZ"
          )} ${data.element.dataset.aliases || ""} ${data.text}`.toLowerCase();
        return _aliasCache[value];
      };

      return (params, data) => {
        if (params.term) {
          return getAlias(data).includes(params.term.toLowerCase())
            ? data
            : null;
        } else {
          setTimeout(() => getAlias(data), 0); // asynchronously populate the cache
          return data;
        }
      };
    })(),
  });
}

window.addDOMContentOrTurboLoadedEventListener(TimeZoneFormatter);
