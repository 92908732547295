import $ from 'jquery';

const loadRecaptcha = async () => {
  if (!window.grecaptcha)
    // wait for grecaptcha SDK to download
    await new Promise((resolve, reject) => {
      $('<script>')
        .insertAfter('body script:last-of-type')
        .on('load', resolve)
        .on('errror', reject)
        .attr('src', 'https://www.google.com/recaptcha/api.js?render=explicit');
    });
  // wait for grecaptcha to be ready
  return new Promise((resolve) => window.grecaptcha.ready(resolve));
};

export default {
  async init() {
    if (gon.rails_env !== 'test') {
      try {
        await loadRecaptcha();
        const form = $(this.$root);
        const target = $('<grecaptcha>').appendTo(form).get(0);

        const widgetId = window.grecaptcha.render(target, {
          callback: () => {
            if (form.get(0).requestSubmit) form.get(0).requestSubmit();
            else
              $('<button type=submit style=height:0;width:0;>')
                .appendTo(form)
                .trigger('click')
                .remove();
          },
          sitekey: this.sitekey,
          size: 'invisible',
        });

        $(form)
          .on('submit', (event) => {
            const invalidForm = form.find(':invalid').length;
            if (invalidForm || !window.grecaptcha.getResponse(widgetId)) {
              event.preventDefault();
              if (!invalidForm) {
                window.grecaptcha.execute(widgetId);
              }
              return false;
            }
          })
          .on('ajax:error', () => {
            window.grecaptcha.reset(widgetId);
          });
      } catch (error) {
        console.error(error);
      }
    }
  },
};

/**
 * Usage:
 * <form x-data="{sitekey: 'RECAPTCHA_SITE_KEY'}" x-init="Alpine.componenets.RecaptchaForm.init"> ... </a>
 * or
 * <form x-data="{sitekey: 'RECAPTCHA_SITE_KEY'}" x-init="function() { Alpine.components.RecaptchaForm.init.apply(this); }"> ... </a>
 */
