// Add the attribute data-copyable-trigger to the element triggering the copy action
// Wrap the content to be copied in a container element (e.g., <div>) and add the attribute data-copyable-container to it.
// Add the attribute data-copyable-content to the content element inside the container element.

document.addEventListener("click", async (e) => {
  if (e.target.matches("[data-copyable-trigger]")) {
    const copyableTrigger = e.target;
    const copyableTriggerValue = copyableTrigger.innerHTML;
    const copyableContainer = copyableTrigger.closest(
      "[data-copyable-container]"
    );
    const copyableContent = copyableContainer.querySelector(
      "[data-copyable-content]"
    );

    const clipboardItem = new ClipboardItem({
      "text/html": new Blob([copyableContent.innerHTML], { type: "text/html" }),
      "text/plain": new Blob([copyableContent.textContent], {
        type: "text/plain",
      }),
    });

    try {
      await navigator.clipboard.write([clipboardItem]);
      copyableTrigger.innerHTML =
        "<i class='fi-icon-check text-acai-700 mr-1'></i> Copied!";
      setTimeout(() => {
        copyableTrigger.innerHTML = copyableTriggerValue;
      }, 2000);
    } catch (err) {
      copyableTrigger.innerHTML =
        "<i class='fi-icon-close text-red-700 mr-1'></i> Something went wrong.. Try again!";
      setTimeout(() => {
        copyableTrigger.innerHTML = copyableTriggerValue;
      }, 3000);
    }
  }
});
