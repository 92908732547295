import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export default {
  data: {
    query: "",
    results: [],
    selected: null,
    storageKey: "impactor_saved",
    draggingIndex: null,
    saved: [],
    save(items) {
      this.saved = items;
      localStorage.setItem(this.storageKey, JSON.stringify(this.saved));
    },
    handleDrop(targetIndex) {
      if (this.draggingIndex === null || this.draggingIndex === targetIndex)
        return;

      const copySet = [...this.saved];
      const draggedItem = copySet[this.draggingIndex];
      copySet.splice(this.draggingIndex, 1);
      copySet.splice(targetIndex, 0, draggedItem);
      this.save(copySet);
      this.draggingIndex = null;
    },
    detectLayout() {},
    isDesktop: false,
    pulledUp: false,
    downloadModal: {
      isDownloading: false,
      download() {},
      show: false,
      form: {
        fields: {
          company: {
            value: "",
            validator() {
              this.error = this.value.trim() ? "" : "This field is required";
            },
            error: "",
          },
        },
        validate(fieldName) {
          this.fields[fieldName].validator();
        },
        isSubmitting: false,
        submitted: false,
        handleSubmit({ saved }) {
          this.isSubmitting = true;
          Object.keys(this.fields).forEach(this.validate.bind(this));
          if (!Object.values(this.fields).find((field) => field.error)) {
            this.submitted = true;
          }
          this.isSubmitting = false;
        },
      },
    },
  },
  init() {
    const _this = this;
    try {
      const cache = JSON.parse(localStorage.getItem(this.storageKey));
      this.saved = cache instanceof Array ? cache : [];
    } catch (_) {
      localStorage.removeItem(this.storageKey);
    }
    this.detectLayout = () => {
      this.isDesktop =
        getComputedStyle(this.$refs.savedContainer).position === "sticky";
    };
    this.downloadModal.download = async (pdf = false) => {
      this.downloadModal.isDownloading = true;
      const target = this.$refs.downloadWrapper;
      let [width, height] = [1121, 792];
      if (pdf) [width, height] = [height, width];

      target.style.width = `${width}px`;
      target.classList.remove("invisible");
      height = Math.max(height, parseInt(getComputedStyle(target).height));
      const canvas = await html2canvas(target, {
        onclone() {
          target.classList.add("invisible");
        },
        windowWidth: width,
        width: width,
        height: height,
        scrollY: 0,
        scale: 2,
        logging: false,
        async: true,
      });

      if (pdf) {
        const pdf = new jsPDF({
          format: [width * 0.5, height * 0.5],
          unit: "px",
        });
        pdf.addImage(
          canvas.toDataURL(),
          "PNG",
          0,
          0,
          width * 0.5,
          height * 0.5
        );
        pdf.save("For-Progress-iKPIs.pdf");
      } else {
        const link = Object.assign(document.createElement("a"), {
          href: canvas.toDataURL(),
          download: "For-Progress-iKPIs.png",
        });
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.downloadModal.isDownloading = false;
    };
    for (const fieldName in this.downloadModal.form.fields) {
      this.$watch(`downloadModal.form.fields.${fieldName}.value`, () =>
        this.downloadModal.form.validate(fieldName)
      );
    }
  },
};
