import $ from 'jquery';
import toastr from 'toastr';

const agreementPreview = {
  showPreview: () => {
    $('.agreement__preview').on('click', (ev) => {
      ev.preventDefault();
      $('#agreementPreviewModal').removeClass('hidden');
    });
  },
  hidePreview: () => {
    $('.agreement__preview--close').on('click', (ev) => {
      ev.preventDefault();
      $('#agreementPreviewModal').addClass('hidden');
    });
  },
  init: () => {
    agreementPreview.showPreview();
    agreementPreview.hidePreview();
  }
}

const optOutOfESign = {
  showPreview: () => {
    $('.optOutOfESign--open').on('click', (ev) => {
      ev.preventDefault();
      $('#optOutOfESignModal').removeClass('hidden');
    });
  },
  hidePreview: () => {
    $('.optOutOfESign--close').on('click', (ev) => {
      ev.preventDefault();
      $('#optOutOfESignModal').addClass('hidden');
    });
  },
  upload_agreement_limiter: () => {
    $("input[type='file']#founder_agreement_upload").on('change', (ev) => {
      if (ev.target.files[0] && ev.target.files[0].size / 1024 / 1024 > 20) {
        toastr.error('File Size should be less than 20MB');
        ev.target.value = '';
      }
    });
  },
  init: () => {
    optOutOfESign.showPreview();
    optOutOfESign.hidePreview();
    optOutOfESign.upload_agreement_limiter();
  }
}

const signAgreementForm = {
  init: () => {
    $('form#sign_agreement_form').on('submit', (ev) => {
      ev.preventDefault();
      if($('form#sign_agreement_form .signature_pad__input').val() === ''){
        toastr.error('Signature is required!');
        return false;
      }else {
        ev.target.submit();
      }
    });
  }
}

window.agreementPreview = {
  init: agreementPreview.init,
};

window.optOutOfESign = {
  init: optOutOfESign.init,
}

window.signAgreementForm = {
  init: signAgreementForm.init
}
