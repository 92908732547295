import $ from "jquery";
import { DateTime } from "luxon";
import select2 from "select2"; // global variables used by jQuery
import "select2/dist/css/select2.min.css";

window.select2 = select2();

$(() => {
  // setup location preferences modal dropdowns
  $("select#location-preference-modal__semester-dropdown").select2({
    width: "100%",
    dropdownParent: $("#location-preference-modal .modal-content"),
    templateResult: (state) => {
      if (!state.element) return state.text;
      return `${state.text} (${state.element.dataset.city}, ${state.element.dataset.country})`;
    },
    matcher: (params, data) => {
      if (params.term) {
        return new RegExp(params.term, "i").test(
          `${data.element.value} ${data.element.dataset.city} ${data.element.dataset.country} ${data.element.dataset.name} ${data.element.dataset.accelerator}`
        )
          ? data
          : null;
      } else return data;
    },
  });

  const getLuxonTimezone = (() => {
    const _cache = {};
    return (tzName) => {
      if (!_cache[tzName])
        _cache[tzName] = DateTime.fromObject({}, { zone: tzName });
      return _cache[tzName];
    };
  })();

  $("select#location-preference-modal__timezone-dropdown").select2({
    width: "100%",
    dropdownParent: $("#location-preference-modal .modal-content"),
    matcher: (() => {
      const _aliasCache = {};
      const getAlias = (data) => {
        const value = data.element.value;
        if (!_aliasCache[value])
          _aliasCache[value] = `${getLuxonTimezone(value).toFormat(
            "ZZZZ ZZZZZ"
          )} ${data.element.dataset.aliases || ""} ${data.text}`.toLowerCase();
        return _aliasCache[value];
      };

      return (params, data) => {
        if (params.term) {
          return getAlias(data).includes(params.term.toLowerCase())
            ? data
            : null;
        } else {
          setTimeout(() => getAlias(data), 0); // asynchronously populate the cache
          return data;
        }
      };
    })(),
  });
});
