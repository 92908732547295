window.addDOMContentOrTurboLoadedEventListener = (
  fn,
  turboEventType = "turbo:load"
) => {
  document.addEventListener("DOMContentLoaded", () => {
    if (window.Turbo) {
      document.addEventListener(turboEventType, fn);
    } else {
      fn();
    }
  });
};
