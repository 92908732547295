import $ from "jquery";
import "select2";

function select2Form() {
  $(".select2-multiple").select2({ multiple: true });
  if ($(".select2-single").length > 0) $(".select2-single").select2();
  $(document).on("select2:open", () => {
    document.querySelector(".select2-search__field").focus();
  });
  if ($(".select2-tags").length > 0) $(".select2-tags").select2();
}

window.addDOMContentOrTurboLoadedEventListener(select2Form);
